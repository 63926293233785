import { Typography } from '@material-ui/core';
import { navigate } from 'gatsby-link';
import React, { useEffect } from 'react';

const CongresoAcademico2021Programa = () => {
  useEffect(() => {
    const redirect = () => {
      if (!window || typeof window === 'undefined') {
        navigate('/');
        return;
      }

      window.open('http://congreso2021.isset.gob.mx:8081/programa', '_self');
    }
    redirect();
  }, [])

  return (
    <div>
      <Typography>Redireccionando al portal de citas médicas, por favor espere.</Typography>
    </div>
  );
};

export default CongresoAcademico2021Programa;